.slick-initialized .slick-slide {
  display: block;
  padding: 0px 15px;
}

.blue_gradient_text p {
  background: -webkit-linear-gradient(
    90deg,
    rgba(0, 80, 213, 1) 0%,
    rgba(0, 16, 43, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
